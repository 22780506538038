
import window_helpers from './../helpers/window.coffee'

export default {
  props: {
    items: {type: Object, default: undefined}
  }

  methods: {
    make_link: (page_name) ->
      return ['', page_name].join('/')

    move_to_page: (page_name, event) ->
      if not event.ctrlKey
        window_helpers.move_to_path([page_name])
        event.preventDefault()
        event.stopPropagation()
      return
  }
}
